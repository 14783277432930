import React from 'react'
import Image from './image';
class iframe extends React.Component {
    state = {  }
    render() { 
        return (
        <div className="video">
            <iframe src={this.props.src} allow={this.props.allow} frameBorder="0"></iframe>
        </div>   
        );
    }
}
 
export default iframe;