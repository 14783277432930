import React from 'react'
import Image from './image';
class iframe extends React.Component {
    state = {  }
    render() { 
        return (
        <div className="player">
            <iframe {...this.props}></iframe>
        </div>   
        );
    }
}
 
export default iframe;