import React from 'react';

class Image extends React.Component {
    state = { open: false }
    
    render() { 
        const { image } = this.props
        const { open } = this.state
        
        const openHandler = () => {
            this.setState({open: !open})
        }

        return (
            <div onClick={openHandler} className="image-holder">
               <img src={typeof image === 'object' ? image.src : image} alt=""/>
               <div className="image__text-holder">
                    <p>{typeof image === 'object' ? image.title : ''}</p>
                    <p>{typeof image === 'object' ? image.alt : 'click for higher resolution'}</p>
                </div>
               {open &&
                    <div className="modal-overlay">
                        <div className="modal-overlay__image">
                            <img width={window.innerHeight} src={typeof image === 'object' ? image.src : image} alt=""/>
                            <div className="image__text-holder">
                                <p style={{color: open && '#fff'}}>{typeof image === 'object' && image.title}</p>
                                <p></p>
                            </div>
                        </div>
                    </div>
               }
            </div>
        );
    }
}
 
export default Image;