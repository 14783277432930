import React from 'react'
import Image from './image';
class p extends React.Component {
    state = {  }
    render() { 
        return (
        <p className="p-render">{this.props.children.map((child, index) => {
            if (child.type === 'img') {
                return (
                    <Image image={child.props} />
                )
            }
            else {
                return (
                    child
                )
            }
        })}</p>   
        );
    }
}
 
export default p;