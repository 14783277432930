import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import ReactMarkdown from 'react-markdown'
import symbol from "../images/symbol.png"
import p from "../components/p";
import iframe from "../components/iframe";
import player from "../components/player";
import Markdown from 'markdown-to-jsx';
import Image from '../components/image'
import SEO from "../components/seo"
import { Redirect } from '@reach/router'

const a = (props) => {
  return (
    <a>{props}</a>
  )
}

class BlogPostTemplate extends React.Component {
  state = {
    post: false
  }

  componentDidMount () {
    const key = localStorage.getItem('key');
    if (key) { 
      fetch('https://yr1press-api.firebaseapp.com/posts', {
        method: 'GET', // or 'PUT'
        headers: {
          'Content-Type': 'application/json',
          'key': key
        },
      }).then((resp) => {
        return resp.json()
      }).then((data) => {
        this.setState({
          post: data
        })
      })
    }else {
      if (typeof window !== `undefined`) window.location.replace(`/`)
    }
  }

  render() {
	  
	const post = this.state.post
	console.log(post)
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext
    const header = (data) => (
      <div>
        <div className="navLogo">
          <img src={symbol} alt=""/>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-7 col-12">
              <div style={{margin: '1.5rem 0'}} className="blog-header d-flex justify-content-between align-items-end flex-wrap">  
                <div>
                  <h1 className="typography-largeTag">{data && data.artist}</h1>
                  <h1 className="typography-largeTag">{data && data.release}</h1>
                </div>
                <div style={{marginBottom: '7px'}}>
                  <span className="text-uppercase">{data && `release date: ${data.date}`}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
    
    if (post) {
    return (
      <Layout header={header(post)} location={this.props.location} title={siteTitle}>
        <SEO title="All releases" />
        <div className="row">
          <div className="col-md-7 col-12">
            <div className="featured-img">
              <Image image={post.image} />
            </div>
            <div className="body">
            <Markdown
                options={{
                    overrides: {
                        p: {
                            component: p,
                            props: {
                              	className: 'paragraph',
                            },
						},
						iframe: {
                            component: iframe,
                            props: {
                              	className: 'paragraph',
                            },
                        },
                    },
                }}
            >
                {post.bodytext}
            </Markdown>
            </div>
          </div>
          <div className="col-md-5 col-12">
            <div className="credits">
              <h2>:RELEASE CREDITS</h2>
              <div className="credits__text">
                <ReactMarkdown source={post.credits} />
              </div>
            </div>
            <div className="player">
              <h2>:Preview player</h2>
			  <Markdown
                options={{
                    overrides: {
						iframe: {
                            component: player,
                            props: {
                              	className: 'player',
                            },
                        },
                    },
                }}
            >
                {post.player.replace(/\“|\”/g, '"')}
            </Markdown>
              {/* <div className="player__holder" dangerouslySetInnerHTML={{__html:post.player}} /> */}
			  
            </div>
            <div className="timeline">
              <h2>:Tracklist</h2>
              <div className="timeline__text">    
                <ReactMarkdown source={post.timeline} />
              </div>
            </div>
            <div className="contacts">
              <h2>:Contacts</h2>
              <div className="contacts__text">
              <Markdown
                options={{
                    overrides: {
                        p: {
                            component: p,
                            props: {
                                className: 'foo',
                            },
                        },
                    },
                }}
            >
                {post.contacts}
            </Markdown>    
               
              </div>
            </div>
            <div className="links">
              <h2>:Links</h2>
              <div className="links__text">  
                <ReactMarkdown source={post.links} />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  } else {
    return (
      <Layout header={header(false)} location={this.props.location} title={siteTitle}>
        <SEO title="All releases" />
        <div className="loading-post" style={{width: "100%", height: "100vh"}}>
          <h3>LOADING...</h3>
        </div>
      </Layout>  
    )
  }
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      body
    }
  }
`
